import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import Section, { Heading3, ParaSmall, RichText } from "./elements/Section";
import AccordionItem, {AccordionItemEl} from "./AccordionItem";
import PropTypes from "prop-types";
import {
	container,
	gridColumnByMargin,
	grid,
	screen,
	spacingRestricted,
	screenMax,
} from "../styles/mixins";
import color, { themeColors } from "../styles/colors";
import { h2, h3, h4, search } from "../styles/typography";
import LinkTo from "./elements/LinkTo";
// import FuzzySet from "fuzzyset.js"
import { transparentize } from "polished";
import { ReactComponent as SearchIcon } from "../images/vectors/search-icon.svg";
import noResultsBackground from "../images/illustrations/no-results-bg.png";

const SectionEl = styled(Section)`
	padding-top: 0 !important;
	& + * {
		padding-top: 0 !important;
	}
`;

const SearchContainer = styled.div`
	${grid(12)};
	${spacingRestricted("padding-top", "m")};
	${spacingRestricted("padding-bottom", "m")};
	${spacingRestricted("margin-bottom", "xl")};
	background-color: white;
	@media ${screen("md")} {
		${container()};
	}
`

const SearchInner = styled.div`
	${gridColumnByMargin(1, 12)};
	display: flex;
	align-items: center;
	@media ${screen("md")} {
		${gridColumnByMargin(9)};
	}	
`

const SearchIconContainer = styled.div`
	margin-right: 0.75em;
	flex-shrink: 0;
	svg {
		width: 100%;
	}
`

const SearchInput = styled.input`
	color: ${props => themeColors[props.colorTheme].fg};
	${search};
	width: 100%;
	&::placeholder {
		color: ${props => transparentize(0.4, themeColors[props.colorTheme].fg)};
	}
`

const ContainerEl = styled.div`
	${grid(12)};
	@media ${screen("md")} {
		${container()};
	}
`;

const AccordionEl = styled.div`
	${gridColumnByMargin(1, 12)};
	${grid(10)};
	border-top: 1px solid;

	@media ${screen("md")} {
		${gridColumnByMargin(9)};
		${grid(30)};
	}
	

	&:last-child {
		margin-bottom: 0;
	}

	${AccordionItemEl} {
		&:last-child {
			${spacingRestricted("padding-bottom", "s")}
		}
	}
`;

const ScrollAnchor = styled.span`
	display: block;
	position: relative;
	visibility: hidden;
	top: -100px;
	@media ${screen("md")} {
		top: -150px;
	}
`

const Intro = styled.h2`
	color: ${props => themeColors[props.colorTheme].fg};
	margin-top: 12px;
	${h2};
	grid-column: span 2;
	font-weight: 500;
	@media ${screen("md")} {
		${spacingRestricted("margin-top", "xxs")};
		grid-column: span 7;
	}
	`;

const TermsContainer = styled.div`
	${spacingRestricted("padding-top", "s")};
	grid-column: span 8;
	@media ${screen("md")} {
		grid-column: 8 / span 23;
	}
`

const NoResults = styled.div`
	${gridColumnByMargin(1, 12)};
	text-align: center;
	${spacingRestricted("padding-top", "xl")}
	${spacingRestricted("padding-bottom", "xl")}
	background-image: url(${noResultsBackground});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	@media ${screen("md")} {
		${gridColumnByMargin(12)};
	}	
`

const TerminologyAccordion = ({ className, terms, colorTheme = "light" }) => {
	const searchableTerms = terms.map(a => a.title) // Array of terms, titles only
	
	const createTermGroup = (array) => {
		const sortedTerms = array.sort(function(a, b) {
			return a.title.localeCompare(b.title);
		});
		const termGroupsArray = []
		if (sortedTerms.length > 0) {
			let obj = {}
			sortedTerms.forEach((item, index) => {
				const { title } = item;
				const firstLetter = title.split('')[0]
				const lastItem = sortedTerms[index - 1] || sortedTerms[0];
				if (index === 0 || firstLetter !== lastItem.title.split('')[0]) {
					obj = {}
					obj.letter = firstLetter
					obj.terms = []
					termGroupsArray.push(obj)
				} 
				if (obj.letter === firstLetter) {
					obj.terms.push(item)
				}
			})
		}	
		return termGroupsArray
	}

	const allGroupedTerms = createTermGroup(terms)

	const [termGroups, setTermGroups] = useState(allGroupedTerms)
	// const fuzzy = FuzzySet(searchableTerms, true, 0, 0);

	const [searchValue, setSearchValue] = useState(null);
	const [inputIsDirty, setInputIsDirty] = useState(false);
	const inputRef = useRef();
	const getResults = (value) => {
		if (value !== 'undefined' && value !== null) {
			const lowercaseValue = value.toLowerCase();
			// const fuzzySearchResults = fuzzy.get(lowercaseValue)
			const exactMatches = searchableTerms.filter(b => b.toLowerCase().split("")[0] === lowercaseValue.split("")[0]).filter(b => b.toLowerCase().includes(lowercaseValue)) || [];
			// const searchResultTitles = fuzzySearchResults
			// 	.filter(a => a[0] >= 0.6) // similarity value
			// 	.map(a => a[1]) // get titles only
			// 	.filter(a => exactMatches.indexOf(a) < 0) // de-duplicate;
			// if (fuzzySearchResults) {
			if (exactMatches.length > 0) {
				// const mergedResults = exactMatches.concat(searchResultTitles);
				const mergedResults = exactMatches;
				const filteredGroups = terms.filter(group => mergedResults.includes(group.title));
				setTermGroups(createTermGroup(filteredGroups));
			} else if (value.length === 0 && inputIsDirty) {
				setTermGroups(allGroupedTerms)
			} else {
				setTermGroups([])
			}
		}

	}

	const handleChange = () => {
		const value = inputRef.current.value;
		setInputIsDirty(true);
		setSearchValue(value);
	}

	useEffect(() => {
		getResults(searchValue)
	}, [searchValue]);


	return ( 
		terms?.length ? (
			<SectionEl colorTheme={colorTheme}>
				<SearchContainer>
					<SearchInner>
						<SearchIconContainer>
							<SearchIcon />
						</SearchIconContainer>
						<SearchInput
							type="text"
							onChange={handleChange}
							ref={inputRef}
							colorTheme={colorTheme}
							placeholder={`Search by keyword. Try “${searchableTerms[Math.floor(Math.random() * searchableTerms.length)]}”`}
						/>
					</SearchInner>
				</SearchContainer>
				<ContainerEl>
					{termGroups.length > 0 ? termGroups.map((group) => 
					{
						return (
						<AccordionEl className={className} colorTheme={colorTheme}>
							<Intro colorTheme={colorTheme}>{group.letter}</Intro>
							<TermsContainer>
								{group.terms?.map((term, i) => (
									<AccordionItem
										heading={term.title}
										key={i}
										colorTheme={colorTheme}
										mainTitle={term.title}>
										<RichText text={term.definition} small={true} />
									</AccordionItem>
								))}
							</TermsContainer>
						</AccordionEl>
					)}) : (
						<NoResults>
							<Heading3>Sorry! No results</Heading3>
							<ParaSmall>Double check your spelling or try another term.</ParaSmall>
						</NoResults>
					)
				}
				</ContainerEl>
			</SectionEl>
		) : null
	)
}

TerminologyAccordion.propTypes = {
	colorTheme: PropTypes.string,
	groups: PropTypes.array,
};

TerminologyAccordion.defaultProps = {
	colorTheme: "light",
	groups: [],
};

export default TerminologyAccordion;
