import React from "react";
import SEO from "../components/seo";
import HeroGeneric from "../components/HeroGeneric";
import Layout from "../components/layout";
import HelpTiles from "../components/HelpTiles";
import HeroGuidesIndex from "../components/HeroGuidesIndex";
import transformTheme from "../libs/transformTheme";
import TerminologyAccordion from "../components/TerminologyAccordion";
import { graphql } from "gatsby";
import Accordion from "../components/Accordion";
import FooterCTA from "../components/FooterCTA";
import SimpleBanner from "../components/SimpleBanner";
import UsefulLinks from "../components/UsefulLinks";

export default function Terminology({ data, pageContext }) {
	const {
		heroTitle,
		heroText,
		indexPageBlocks,
		metaTitle,
		metaDescription,
		metaShareImage,
		faqsTitle,
		faqsItems,
		footerBannerTitle,
		footerBannerText,
		footerBannerButton,
		simpleBannerTitle,
		simpleBannerText,
		simpleBannerButton,
		simpleBannerImage,
		simpleBannerImageMobile,
		usefulLinkEntries
	} = data.craft.entry ?? [];

	const terms = pageContext?.glossaryTerms?.data?.craft?.entries ?? [];
	const heroNav = pageContext?.heroNav?.data?.craft;
	const { currentUrl } = pageContext;

	const { lightTheme, darkTheme } = transformTheme("dark");

	return (
		<Layout className="layout--helpIndex">
			<SEO
				title={metaTitle}
				description={metaDescription}
				image={metaShareImage}
			/>

			<HeroGuidesIndex
				title="Terminology"
				colorTheme={darkTheme}
				navLinks={heroNav}
				currentUrl={currentUrl}
			/>

			<TerminologyAccordion
				terms={terms}
				colorTheme="greenLight"
			/>

			<UsefulLinks
				colorTheme={lightTheme}
				entries={usefulLinkEntries}
			/>

			<SimpleBanner
				title={simpleBannerTitle}
				text={simpleBannerText}
				cta={simpleBannerButton}
				image={simpleBannerImage}
				mobileImage={simpleBannerImageMobile}
			/>

			<Accordion title={faqsTitle} items={faqsItems} colorTheme="dark" />

			<FooterCTA
				colorTheme="dark"
				title={footerBannerTitle}
				text={footerBannerText}
				cta={footerBannerButton}
			/>

		</Layout>
	);
}

export const pageQuery = graphql`
	fragment UsefulLinkExcerpt on CraftCMS_guidesInsights_guides_Entry {
		uri
		url
		slug
		title
		heroImage {
			title
			filename
			width
			height
			url
			imagerX(transform: "maxWidth-1920") {
				width
				height
				url
			}
			imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
				width
				height
				url
			}
		}
		guideCategory {
			id
			title
			... on CraftCMS_guidesAndInsightsCategories_Category {
				id
				categoryTheme
				uri
			}
		}
	}
	query terminology($id: CraftCMS_QueryArgument!) {
		craft {
			entry(id: [$id]) {
				id
				... on CraftCMS_terminology_terminology_Entry {
					title
					heroTitle
					usefulLinkEntries {
						... on CraftCMS_guidesInsights_guides_Entry {
							...UsefulLinkExcerpt
						}
					}
					simpleBannerTitle
					simpleBannerText
					simpleBannerButton {
						element {
							slug
							uri
						}
						ariaLabel
						customText
						target
						text
						title
						type
						url
					}
					simpleBannerImage {
						title
						width
						height
						url
						imagerX(transform: "maxWidth-1920") {
							width
							height
							url
						}
						imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
							width
							height
							url
						}
					}
					simpleBannerImageMobile {
						title
						width
						height
						url
						imagerX(transform: "maxWidth-1920") {
							width
							height
							url
						}
						imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
							width
							height
							url
						}
					}
					faqsTitle
					faqsItems {
						... on CraftCMS_faqsItems_faqsItem_BlockType {
							id
							faqsItemTitle
							faqsItemText
						}
					}
					footerBannerTitle
					footerBannerText
					footerBannerButton {
						element {
							slug
							uri
						}
						ariaLabel
						customText
						target
						text
						title
						type
						url
					}
					metaTitle
					metaDescription
					metaShareImage {
						filename
						url
					}
				}
			}
		}
	}
`;
